<template>
    <div class="tw-border tw-border-gray-200 tw-text-gray-500">
    <q-input
        dense
        square
        borderless
        v-model:model-value="search"
        class="tw-text-base tw-w-full tw-font-extralight tw-font-signika !tw-text-white md:tw-text-gray-400 tw-pl-2"
        @keyup.enter="searchInStore"
        :placeholder="$t('actions.search_product')"
        :input-class="{'tw-text-white':shouldShowMobileMenu}"
    >

        <template v-slot:append>
            <q-btn icon="close" flat  @click="cleanSearchInStore" v-if="search" class="tw-text-white md:tw-text-gray-400"></q-btn>
        </template>
        <template v-slot:after>
            <div class="tw-border-l tw-border-gray-100 md:tw-border-gray-200 tw-h-full tw-flex tw-pl-2 ">
                
                <q-select :options-dark="false"
                            :options-dense="shouldShowMobileMenu"
                            square 
                            dense
                            borderless
                            v-model="model_deparment" 
                            :options="departments" emit-value map-options option-label="label" option-value="id"
                            :class="{selected_department:shouldShowMobileMenu}"
                            >
                     <template #selected>
                            <span class="tw-text-white tw-w-[150px] tw-text-ellipsis tw-whitespace-nowrap tw-overflow-hidden md:tw-text-gray-400 tw-font-signika tw-text-base tw-font-normal">
                                {{ deparment_label }} 
                            </span>
                     </template>       

                     <template #option="scope">
                        <q-item v-bind="scope.itemProps" class="!tw-text-gray-400 tw-font-signika tw-text-base tw-font-normal">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.label }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>    
                     </template>

                    
                 </q-select>
                <q-btn icon="search" square dense :color="shouldShowMobileMenu ? 'white' : 'primary'" size="sm" @click="searchInStore" class="tw-px-4 tw-cursor-pointer" :class="{'!tw-text-primary-default':shouldShowMobileMenu}"/>
            </div> 
        </template>
    </q-input>
</div>
    <!-- <div class="tw-flex buscador md:tw-flex tw-w-full md:tw-w-[45rem] tw-bg-white tw-rounded-full header-shaw tw-items-center tw-h-10 tw-mt-12 md:tw-mt-0">
        
         class="tw-bg-transparent tw-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M6.53663 3.95843C5.01774 5.47509 4.13432 7.51384 4.0664 9.65922C3.99848 11.8046 4.75116 13.8952 6.17107 15.5049C7.59099 17.1146 9.57126 18.1223 11.7083 18.3226C13.8156 18.5202 15.919 17.9181 17.6017 16.6379L22.0043 21.0416L22.075 20.9709L22.0043 21.0416C22.1104 21.1476 22.2363 21.2318 22.3749 21.2892C22.5134 21.3466 22.662 21.3761 22.812 21.3761C22.962 21.3761 23.1105 21.3466 23.2491 21.2892C23.3877 21.2318 23.5136 21.1476 23.6197 21.0416C23.7257 20.9355 23.8099 20.8096 23.8673 20.671C23.9247 20.5324 23.9542 20.3839 23.9542 20.2339C23.9542 20.0839 23.9247 19.9353 23.8673 19.7968C23.8099 19.6582 23.7257 19.5323 23.6197 19.4262L23.549 19.4969L23.6197 19.4262L19.2164 15.0239C20.4931 13.341 21.0925 11.2391 20.8938 9.13382C20.6923 6.99853 19.6849 5.02022 18.0765 3.60134C16.4682 2.18247 14.3796 1.42962 12.2359 1.49596C10.0921 1.56229 8.0542 2.44283 6.53663 3.95843ZM6.53663 3.95843L6.60729 4.02919L6.53663 3.95843ZM16.8497 5.57444C17.4284 6.1438 17.8886 6.82212 18.2039 7.57025C18.5191 8.31839 18.6832 9.12154 18.6865 9.93337C18.6898 10.7452 18.5323 11.5497 18.2231 12.3003C17.914 13.051 17.4593 13.7331 16.8852 14.3071C16.3112 14.8812 15.6291 15.3359 14.8784 15.6451C14.1278 15.9542 13.3233 16.1117 12.5115 16.1084C11.6996 16.1051 10.8965 15.941 10.1483 15.6258C9.40021 15.3105 8.7219 14.8503 8.15253 14.2716C7.0147 13.115 6.37995 11.5558 6.38656 9.93337C6.39316 8.31098 7.04059 6.75692 8.1878 5.60971C9.33501 4.46249 10.8891 3.81507 12.5115 3.80846C14.1339 3.80186 15.6931 4.4366 16.8497 5.57444Z" fill="black" stroke="black" stroke-width="0.2"/>
            </svg>
        </button>
    </div> -->

</template>
<script setup lang="ts">
import {computed, ref} from 'vue';

const router = useRouter();
const route = useRoute();
const nuxtApp = useNuxtApp()

const queryStore = useSearchStore()
const {query, category} = storeToRefs(queryStore)

const search = ref<string|null>(query.value)

const departmentsStore = useDepartmentsStore()
const {departments} = storeToRefs(departmentsStore)
const screenWidth = ref(800);
const shouldShowMobileMenu = ref(screenWidth.value < 745);
const model_deparment= computed({
    get:()=>category.value,
    set:(value)=>{
        queryStore.setCategory(value)
    }
})


const handleResize = () => {
    screenWidth.value = window.innerWidth;
    shouldShowMobileMenu.value = screenWidth.value < 745;
  };

onMounted(() => {
    if(route.query.query) {
        queryStore.setQuery(route.query.query as string)
        search.value = query.value  
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    nuxtApp.$bus.$on('clean_search',()=>{
        cleanSearchInStore()
    })

})

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

const deparment_label = computed(()=>{
    return departments.value.find(department => department.id == model_deparment.value)?.label
})

const searchInStore =()=> {
    queryStore.setQuery(search.value)
    queryStore.setCategory(model_deparment.value)

    if(route.path != '/store')
        router.push({path: '/store', query: {...route.query, query : search.value, category: model_deparment.value}})

    nuxtApp.$bus.$emit('search_action')    
}

const cleanSearchInStore =()=>{
    search.value = ''
    model_deparment.value = -1
    queryStore.setQuery(search.value)
    queryStore.setCategory(model_deparment.value)
    const queries = route.query
    delete queries.query
    delete queries.category

    router.push({path: '/store', query: {...queries}})
}

</script>

<style >

    .selected_department .q-field__append i.q-icon{
        color :white !important; 
    }
</style>