<template>
    <div class="tw-flex" >
        <a
            class="tw-mx-2 tw-cursor-pointer"
            v-for="network in contacts?.networks"
            @click="openNetwork(network.url)"
            v-show="network.url"
        >
            <q-icon class="hover:!tw-text-primary-default" :name="network.icon" :color="color ?? 'white'" size="24px" />
        </a>
    </div>
</template>

<script setup lang="ts">

const props= defineProps({
    color: String
})
onMounted(() => {
    
})
const defaultSettingsStore = useDefaultSettingsStore()
  const {settings} = storeToRefs(defaultSettingsStore)

  const contacts = computed(() => settings.value.data?.contacts)
function openNetwork(url:string){
  window.open(url)
}

</script>
