<template>
    <!-- Sub Header -->
    <div class="home-nav-px tw-w-full  tw-flex tw-flex-col tw-h-full tw-gap-2 lg:tw-justify-between lg:tw-flex-row ">
      <div class="tw-flex tw-flex-col tw-items-start tw-text-left lg:tw-flex-row lg:tw-gap-12 tw-w-full">
        
          <q-btn-dropdown
            v-if="!showDropdow"
            class="tw-text-white tw-font-signika tw-font-semibold  tw-h-full tw-m-0 tw-text-sm lg:tw-text-xl tw-text-left tw-z-50 tw-bg-primary-default"
            flat
            dropdown-icon="sort">
            <template #label>
              <span class="tw-text-white tw-font-signika tw-font-semibold tw-mr-14 tw-normal-case">
                {{ $t('actions.shop_by_category') }}
              </span>
            </template>

            <q-list class="tw-z-50 tw-px-5">
              <q-item clickable v-close-popup v-for="(d,i) in categories" @click="goToStore(d)">
                <q-item-section>
                  <q-item-label class="!tw-text-sm lg:!tw-text-base tw-uppercase ">{{ d.label  }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>

          </q-btn-dropdown>
        
        <div v-else>
          <q-tabs
              v-model="tab"
              dense
              class="tw-mr-2 tw-mt-2 tw-p-2 tw-text-sm lg:tw-text-xl tw-text-secondary-default md:tw-font-[500] tw-font-[600]"
              active-color="secondary"
              indicator-color="secondary"
              align="justify"
          >
            <q-tab name="departments" :label="$t('fields.categories')" />
            <q-tab name="admin" :label="$t('home.menu')" v-if="main_menu_filtered.length > 0"/>
          </q-tabs>
          <q-tab-panels v-model="tab">
             <q-tab-panel name="departments">
               <q-tree
               :nodes="categories"
               node-key="label"
               no-connectors
               text-color="black"
               selected-color="secondary"
               v-model:selected="selected"
               >
                 <template #default-header="props">
                                <span class="tw-gap-4
                                            tw-w-full
                                            tw-pl-2
                                            tw-text-lg
                                            tw-pb-2
                                            tw-border-b-[1px]
                                            tw-uppercase
                                            tw-border-primary/50"
                                            @click="goToStore(props.node)">
                                    {{ props.node.label }}
                                </span>
                 </template>
               </q-tree>
             </q-tab-panel>
             <q-tab-panel name="admin">
               <LeftMenu :menu="main_menu_filtered" class="tw-pl-6 tw-text-black"/>
             </q-tab-panel>
          </q-tab-panels>

        </div>
          <div v-if="showDropdow" class="tw-mt-[-10px] q-tab relative-position self-stretch flex flex-center text-center q-tab--active text-secondary q-focusable q-hoverable cursor-pointer" tabindex="0" role="tab" aria-selected="true">
              <div class="q-focus-helper" tabindex="-1"></div>
              <div class="self-stretch q-tab__content flex-center relative-position q-anchor--skip non-selectable column">
                  <div class="q-tab__label">
                    <NuxtLink :to="'/contact-us'" class="q-tab__label tw-self-center tw-text-secondary-default md:tw-font-[500]">
                      {{ $t('home.contact_us') }}
                    </NuxtLink>
                  </div>
              </div>
              <div class="q-tab__indicator absolute-bottom text-secondary">
              </div>
          </div>
          <div v-else class="tw-flex tw-flex-row tw-text-white tw-font-signika tw-font-semibold tw-text-center tw-h-full">
            <NuxtLink  :to="'/information/about'" class="tw-self-center tw-mr-2 tw-p-2 tw-text-sm lg:tw-text-xl md:tw-font-[500] tw-font-[600]">
              {{ $t('home.about_us') }}</NuxtLink>
              <NuxtLink  :to="'/contact-us'" class="tw-self-center tw-mr-2 tw-p-2 tw-text-sm lg:tw-text-xl md:tw-font-[500] tw-font-[600]">
              {{ $t('home.contact_us') }}</NuxtLink>
          </div>

      </div>
      <div class="tw-flex tw-text-center tw-items-center tw-bg-secondary-700 tw-justify-center tw-justify-items-center tw-w-1/4 ">
        <div class="tw-text-white tw-w-full" id="selectStorage" >
          <q-btn-dropdown
            v-if="!showDropdow"
            class="tw-bg-secondary-700 tw-font-signika tw-justify-between tw-font-semibold tw-m-0 tw-text-sm lg:tw-text-xl tw-text-left tw-z-50 tw-w-full tw-block"
            flat
            >
            <template #label>
              <span class="tw-font-signika tw-font-semibold tw-normal-case">
                {{ storagesWithAllOption.find((point:PointOfSale)=>point.id == storeSelected?.id)?.name || $t('home.select_local') }}
              </span>
            </template>

            <q-list class="tw-z-50">
              <q-item clickable v-close-popup v-for="(d,i) in storagesWithAllOption" @click="storeSelectedHandle(d)">
                <q-item-section>
                  <q-item-label class="!tw-text-sm lg:!tw-text-base">{{ d.name  }}</q-item-label>
                  <q-item-label caption>{{ d.address }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>

          </q-btn-dropdown>
        </div>
      </div>
    </div>
    <!-- Sub Header -->
</template>

<script lang="ts" setup>
import type { Category } from '../models/Category';
import { useQuasar } from 'quasar'
import {computed, ref} from "vue";
import {useLeftMenu} from "../composables/LefMenu";
import Brands from './Brands.vue';
import type { PointOfSale } from '~/models/PointOfSale';
import type { Product } from '~/models/Product';
import { useStorageStore } from '../stores/storageStore';

const {paginator: products, pages, page, loading: productsLoading, handlePaginate: getProducts, addFilter, removeFilter} = usePagination<Product>('store/products', 'GET', 'products')

const departmentsStore = useDepartmentsStore()
const storagesStore = useStoragesStore()
const {departments} = storeToRefs(departmentsStore)
const {storages} = storeToRefs(storagesStore)
const storeSelected = ref<PointOfSale | null>(null);
const storageStore = useStorageStore()

const i18n = useI18n()
const storagesWithAllOption = computed(()=> {
  return [
        { id: 0, name: i18n.t('home.select_local'), address: "" },
        ... storages.value,
      ];
})

const $q = useQuasar();
const router = useRouter()
const $i18n = useI18n()
const nuxtApp = useNuxtApp()

$q.screen.setSizes({ sm: 640, md: 768, lg: 1024, xl: 1280 })

const colorBrands = computed(()=>{
  return $q.screen.lt.lg ? 'secundary':'white'
})

const showDropdow = computed(()=>{
      return $q.screen.lt.md
})

const allDepartments = computed(()=>{
  let array:any = []
  departments.value.forEach(department=>{
    array = array.concat(SplitArrayCategory(department))
  })

  const position = array.shift()

  array.sort((a: { label: string; },b: { label: any; })=>a.label?.localeCompare(b.label))
  array.unshift(position)

  return array

})

const categories = computed(()=>{
  return  $q.screen.lt.lg ?  departments.value : allDepartments.value
})

onMounted(() => {

  useNuxtApp().$bus.$on('update:poinOfSales',()=>{
    storagesStore.fetchStorages()    
    departmentsStore.fetchDeparments()
    });
  useNuxtApp().$bus.$on('update:storeSelected',(newStoreSelected)=>{
    storeSelected.value = newStoreSelected
    });

  const storedValue = localStorage.getItem('storeSelected');   
    if (storedValue !== null) {  
        const storeSelectedValue = JSON.parse(storedValue);  
        storeSelected.value = storeSelectedValue;  
    }

  if(departments.value.length == 0) {
    departmentsStore.fetchDeparments()
  }

  if(storages.value.length == 0) {
    storagesStore.fetchStorages()
  }

})

onUnmounted(() => {
  nuxtApp.$bus.$off('update:pointOfSales');
  nuxtApp.$bus.$off('update:storeSelected');
});

function storeSelectedHandle(newStoreSelected: PointOfSale){
  storeSelected.value = newStoreSelected
  if(storeSelected.value){
      localStorage.setItem('storeSelected', JSON.stringify(storeSelected.value));
      nuxtApp.$bus.$emit('update:product');
      nuxtApp.$bus.$emit('update:product2');
    }
}

function SplitArrayCategory(category : Category){

  let list = [category]

  if(category.children && category.children.length>0)
  {
    for(const children of category.children)
    {
      list = list.concat(SplitArrayCategory(children))
    }
  }

  return list

}

function goToStore(category?:Category)
{
 
  if(category) 
    router.push({path:`/store`, query: {category: category.id}});
  else
    router.push({path: '/store'});
}
const selected =  computed(() => {

  return findSelectedCategory(categories.value as Category[])

})

const tab = ref('departments')
const main_menu_filtered = useLeftMenu()


function findSelectedCategory(categories: Category[]): Category | null {

  for (const category of categories) {

    if (category.selected) {

      return category
    }

    if (category.children) {

      const child = findSelectedCategory(category.children)

      if (child) {

        return child
      }
    }
  }

  return null
}


</script>

<style>
.tw-dropdown:hover .tw-dropdown-menu, .tw-dropdown-menu:hover {
  display: block;
  z-index: 9;
}

.tw-dropdown:hover  svg{
  transform: rotateX(180deg);
}

#selectStorage span.q-btn__content {  
  justify-content: space-between;  
} 
</style>
