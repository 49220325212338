<template>
    <div class="footer tw-py-8 home-nav-px">
        <div class="tw-mx-auto">

            <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-x-12 tw-gap-y-12 md:tw-justify-between tw-justify-start tw-items-start">
                <nuxt-link to="/">
                    <ApplicationLogo></ApplicationLogo>
                </nuxt-link>
                   

                    <div class="footer-list lg:tw-max-w-[35%]">
                        <label>{{ $t('home.contact') }}</label>
                        <ul class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-gap-2">
                            <li><q-icon name="place" color="white"/><span @click="openNetwork('http://www.google.com/maps/place/'+ contacts?.lat +',' + contacts?.long)" class="footer-list tw-underline tw-underline-offset-1 tw-cursor-pointer">{{ contacts?.address }}</span> </li>
                            <li><q-icon name="phone" color="white" class="tw-mr-2"/>{{ contacts?.phones.join(', ') }}</li>
                            <li><q-icon name="mail" color="white" class="tw-mr-2"/>{{ contacts?.email }}</li>
                            <li class="tw-flex tw-flex-row tw-items-center"><q-icon name="watch_later" color="white" class="tw-mr-2"/>  <div class="tw-flex tw-flex-col tw-w-full tw-break-words"><span v-for="day in scheduleLabels">{{ day }}</span></div> </li>
                        </ul>
                        
                    </div>

                    <!-- <div class="footer-list lg:tw-max-w-[35%]"> 
                        <label>{{ $t('home.links') }}</label>
                    </div> -->

                    <div class="footer-list lg:tw-max-w-[20%]">
                        <template v-if="hasAnyInformation">
                        <label>{{ $t('settings.information') }}</label>
                            <ul class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-gap-2">
                              <li v-if="information.enable_about_us">
                                <NuxtLink to="/information/about">
                                  <span class="tw-pr-1 tw-text-white tw-text-base">{{ $t('settings.about_us') }}</span>
                                </NuxtLink>
                              </li>
                                <li v-if="information?.enable_term_conditions">
                                    <NuxtLink to='/information/terms-conditions'>
                                        <span class="tw-pr-1 tw-text-white tw-text-base">{{ $t('settings.terms_conditions') }}</span>
                                    </NuxtLink>
                                </li>
                                <li v-if="information?.enable_warranty_policy">
                                    <NuxtLink to="/information/warranty-policy">
                                        <span class="tw-pr-1 tw-text-white tw-text-base">{{ $t('settings.warranty_policy') }}</span>
                                    </NuxtLink>
                                </li>
                                <li v-if="information?.enable_shipment_policy">
                                    <NuxtLink to="/information/shipment-policy">
                                        <span class="tw-pr-1 tw-text-white tw-text-base">{{ $t('settings.shipment_policy') }}</span>
                                    </NuxtLink>
                                </li>
                                <li v-if="information?.enable_return_policy">
                                    <NuxtLink to="/information/return-policy">
                                        <span class="tw-pr-1 tw-text-white tw-text-base">{{ $t('settings.return_policy') }}</span>
                                    </NuxtLink>
                                </li>
                                <li v-if="information?.enable_frequent_asks">
                                    <NuxtLink to="/information/frequent-asks">
                                        <span class="tw-pr-1 tw-text-white tw-text-base">{{ $t('settings.frequent_asks') }}</span>
                                    </NuxtLink>
                                </li>
                            </ul>
                        </template>
                    </div>
                    
            </div>
        </div>
    </div>

    <div className="tw-w-full tw-h-[0px] tw-border-t tw-border-white tw-bg-white"></div>

    <div class="footer tw-py-8 home-nav-px">
        <div class="tw-flex tw-justify-between tw-gap-8 tw-flex-col md:tw-flex-row sm:tw-flex-row tw-items-center tw-mx-auto">
            <div class="tw-order-last md:tw-order-first">
                <h6 className="tw-text-left tw-text-white tw-text-xs tw-font-medium tw-font-signika tw-leading-none">{{ $t('home.copyright') }}</h6>
            </div>

            <div class="">
                <ul class="tw-list-none tw-flex">
                    <li><img src="../public/img/logo-tropipay-header-2.png" alt="" class="images_log"></li>
                    <li><img src= '../public/img/Visa-logos1.png' alt="" class="images_log"></li>
                    <li><img src="../public/img/masterCard.png" alt="" class="images_log"></li>
                    <li><img src="../public/img/union-pay.png" alt="" class="images_log"></li>
                    <li><img src="../public/img/discover.png" alt="" class="images_log"></li>
                </ul>
            </div>

            
            <div class="redes sociales tw-order-first md:tw-order-last">
                <Brands :color="'white'"></Brands>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import ApplicationLogo from './ApplicationLogo.vue';
import {ref} from "vue";
import Brands from './Brands.vue';
import { useI18n } from 'vue-i18n';

const i18n= useI18n()

const defaultSettingsStore = useDefaultSettingsStore()
const {settings} = storeToRefs(defaultSettingsStore)

const information = computed(() => {
    return settings.value.data?.information
})

const contacts = computed(() => settings.value.data?.contacts)

const label_schedule_footer = computed(() => {
    return settings.value.data?.contacts?.schedule
})

const scheduleLabels = computed(() => {
    const dayWeek = [i18n.t('week.1'),i18n.t('week.2'),i18n.t('week.3'),i18n.t('week.4'),i18n.t('week.5'),i18n.t('week.6'),i18n.t('week.7')]
    const labels = ref<string[]>([]);
    if (label_schedule_footer && label_schedule_footer.value && label_schedule_footer.value.length > 0) {
        label_schedule_footer.value.forEach((schedule: { day_week: any[]; hours: string | any[]; }) => {
            let label = '';

            schedule.day_week.forEach(week => {
                if (Array.isArray(week) && week.length === 2) {
                label += (label ? ', ' : '') + dayWeek[week[0]-1] + ' a ' + dayWeek[week[1]-1];
                } else {
                label += (label ? ', ' : '') + dayWeek[week-1];
                }
            });

            if (schedule.hours.length === 1) {
                label += ': ' + schedule.hours[0].timeInit + ' - ' + schedule.hours[0].timeEnd;
            } else if (schedule.hours.length === 2) {
                label += ': ' + schedule.hours[0].timeInit + ' - ' + schedule.hours[0].timeEnd + ' y ' +
                        schedule.hours[1].timeInit + ' - ' + schedule.hours[1].timeEnd;
            }

            labels.value.push(label);
        });
    } else { 
        labels.value = []
    }

    return labels.value;
});

const hasAnyInformation = computed(() => {
    return information.value?.enable_term_conditions ||
        information.value?.enable_warranty_policy ||
        information.value?.enable_shipment_policy ||
        information.value?.enable_return_policy ||
        information.value?.enable_frequent_asks
    
})

function openNetwork(url:string){
    window.open(url)
}
</script>

<style scope>
    .footer{
        @apply
        tw-bg-primary-700
    }

    .footer-list
    {
        @apply tw-p-2 tw-text-white tw-text-center lg:tw-text-left tw-mx-auto lg:tw-mx-0;
    }

    .footer-list label{
        @apply tw-text-xl tw-normal-case tw-font-bold;
    }
    .footer-list li{
        @apply tw-pr-1 tw-text-white tw-text-base;
    }
    .images_log{
      @apply
      tw-w-10 tw-h-5 tw-mx-2
    }

</style>
