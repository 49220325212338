import { defineStore } from "pinia";
import { main_menu } from "../common/main_menu";
import type { MenuItem } from "../models/MenuItem";
import { useLeftMenu } from "../composables/LefMenu";

interface TrailItem {
    title: string,
    selected: boolean
}

export const useMenuItemSelectedStore = defineStore('menuItemSelected', {
    state: () => ({
        expands: [''],
        routeSelected: ''
    }),
    getters: {
        getExpands: (state) =>
        {
            return state.expands
        }
    },
    actions: {
        setByRoute(route: string){

            const menu = useLeftMenu()

            const expands: TrailItem[] = []

            findSelectedTrailByRoute(menu, route, expands)

            if(expands.length > 0)
            {
                this.routeSelected = route
                this.expands = expands.map(item => item.title) as []
            }
        }
    }
})

function findSelectedTrailByRoute(menu: MenuItem[], route: string, trail: TrailItem[] = [])
{
    menu.forEach((item) => {

        if(item.route == route){
            trail.push({title: item.title, selected: true})
            return
        }

        if(item.children){

            trail.push({title: item.title, selected: false})

            findSelectedTrailByRoute(item.children, route, trail)

            if(trail[trail.length-1].selected == false){
                trail.pop()
            }
        }
        else{
            return
        }

    })
}