import type { PointOfSale } from "~/models/PointOfSale"

export const useStoragesStore = defineStore('storagesStore', {
    state: (): { storages: PointOfSale[] } => ({
        storages: [] as PointOfSale[]
    }),
    actions: {
        setStorages(storages: PointOfSale[]) {
            this.storages = storages
        },
        fetchStorages() {
            const {loading, get} = useOFetchCustom('storages')

            get(undefined, {
                onSuccess: ({data}) => {
                    this.setStorages(data)
                }
            })
        }
    }
})