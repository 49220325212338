import type { User } from "~/models/User";
import type {MenuItem} from "../models/MenuItem";



export const profile_menu: MenuItem[] = [
    // {
    //     title: 'shoppingCard.my',
    //     icon: 'credit_card',
    //     route: 'shoppingCard.my',
    //     color: 'grey-9',
    //     can: props => props.auth.user != null
    // },
    {
        title: 'orders.my',
        route: '/orders/ownerOrder',
        icon: 'request_quote',
        color: 'grey-9',
        can: user => user != null
    },
    {
        title: 'fields.beneficiary',
        route: '/beneficiary',
        icon: 'users',
        color: 'grey-9',
        can: user => user != null
    },
    {
        title: 'reservations.my',
        route: '/reservations/ownerReservation',
        icon: 'book_online',
        color: 'grey-9',
        can: (user:User) =>(user != null && user.company?.aproved)
    },

]

export const navbar_menu: {
    route_name: string,
    route_current: string,
    show_condition: Function | null,
    name: string
}[] = [
    {
        route_name: 'store',
        route_current: 'store',
        show_condition: null,
        name: 'home.store'
    },
    {
        route_name: 'contact',
        route_current: 'contact',
        show_condition: null,
        name: 'home.contact_us'
    },
    {
        route_name: 'about',
        route_current: 'about',
        show_condition: (page: { props: { information: { enable_about_us: any; }; }; }) => page.props.information.enable_about_us,
        name: 'home.about_us'
    },
]

/**
 * Main menu
 *
 * @author Abel David.
 */
export const main_menu: MenuItem[] = [
    {

        title: 'nav.manage',
        icon: 'work',
        color: 'grey-9',
        children: [
            {
                title: 'users.title',
                icon: 'group',
                color: 'grey-9',
                children: [
                    {
                        title: 'users.title2',
                        route: '/users',
                        icon: 'fa-solid fa-user',
                        color: 'grey-9',
                        can: 'read_users'
                    },
                    {
                        title: 'companies.title',
                        route: '/companies',
                        icon: 'fa-solid fa-building',
                        color: 'grey-9',
                        can: 'read_companies'
                    },
                ]
            },
            {
                title: 'roles.title',
                route: '/roles',
                icon: 'verified_user',
                color: 'grey-9',
                can: 'read_roles'
            },
            {
                title: 'point_of_sales.title',
                route: '/pointOfSales',
                icon: 'fa-solid fa-warehouse',
                color: 'grey-9',
                can: 'read_pos'
            },
            {
                title: 'products.title',
                icon: 'fa-solid fa-tags',
                color: 'grey-9',
                children: [
                    {
                        title: 'products.list',
                        route: '/products',
                        icon: 'fa-solid fa-tag',
                        color: 'grey-9',
                        can: 'read_products',
                    },
                    {
                        title: 'categories.title',
                        route: '/categories',
                        icon: 'fa-solid fa-layer-group',
                        color: 'grey-9',
                        can: 'read_categories'
                    }
                ]
            },
            {
                title: 'sale.title',
                icon: 'attach_money',
                color: 'grey-9',
                children: [
                    {
                        title:'operation.title',
                        route:'/operation',
                        icon:'fa-solid fa-scale-balanced',
                        color:'grey-9',
                        can:'read_orders'
                    },
                    {
                        title: 'invoices.title',
                        route: '/invoices',
                        icon: 'receipt',
                        color: 'grey-9',
                        can: 'read_invoices'
                    }, {
                        title: 'orders.title',
                        route: '/orders',
                        icon: 'fa-solid fa-cart-shopping',
                        color: 'grey-9',
                        can: 'read_orders'
                    },
                    {
                        title: 'reservations.title',
                        route: '/reservations',
                        icon: 'book_online',
                        color: 'grey-9',
                        can:'read_orders'
                    },
                ]
            },
            {
                title: 'offers.title',
                route: '/offers',
                icon: 'shop',
                color: 'grey-9',
                can: 'read_offers'
            },
            {
                title: 'banners.title',
                route: '/banners',
                icon: 'image',
                color: 'grey-9',
                can: 'read_banners'
            },
            {
                title: 'orderStatuses.title',
                route: '/orderStatuses',
                icon: 'update',
                color: 'grey-9',
                can: 'read_orderStatuses'
            }, {
                title: 'report.title',
                icon: 'fa-solid fa-sheet-plastic',
                color: 'grey-9',
                can: 'read_reports',
                children: [
                    {
                        title: 'report.for_sale',
                        route: '/reports/sale',
                        icon: 'fa-solid fa-coins',
                        can: 'read_for_sale_reports',
                        color: 'grey-9',
                    },
                    {
                        title: 'report.for_inventary',
                        route: '/reports/inventory',
                        icon: 'fa-solid fa-sheet-plastic',
                        color: 'grey-9',
                        can: 'read_for_inventary_reports',

                    },
                    {
                        title: 'report.movements',
                        color: 'grey-9',
                        icon: 'fa-solid fa-dolly',
                        route: '/reports/movements',
                        can: 'read_for_movement_reports'
                    },
                    {
                        title:'report.sold_product',
                        color: 'grey-9',
                        icon:'fa-solid fa-chart-line',
                        route: '/reports/soldProduct',
                        can:'read_for_sold_products'
                    }

                ]
            }
        ]
    },
    {
        title: 'settings.title',
        icon: 'settings',
        color: 'grey-9',
        children: [
            {
                title: 'settings.generals',
                route: '/settings/generals',
                icon: 'settings_suggest',
                color: 'grey-9',
                can: ['read_settings', 'update_settings']
            },
            {
                title: 'settings.customization',
                route: '/settings/customization',
                icon: 'palette',
                color: 'grey-9',
                can: ['read_settings', 'update_settings']
            },
            {
                title: 'settings.coins',
                route: '/coins',
                icon: 'monetization_on',
                color: 'grey-9',
                can: 'read_coins'
            }
        ]
    }
]
