<script setup lang="ts">
    const props = defineProps({
        mail:String
    })

</script>
<template>
    <div class="tw-flex tw-text-gray-300 tw-gap-2">
        <q-icon name="mail" size="18px" />
        <span>{{ props.mail }}</span>
    </div>
</template>