import type { Category } from "~/models/Category"

export const useDepartmentsStore = defineStore('departmentsStore', {
    state: (): { departments: Category[] } => ({
        departments: [] as Category[]
    }),
    actions: {
        setDeparments(departments: Category[]) {
            this.departments = departments
        },
        fetchDeparments() {
            const {loading, get} = useOFetchCustom('departments')

            get(undefined, {
                onSuccess: ({data}) => {
                    this.setDeparments(data)
                }
            })
        }
    }
})