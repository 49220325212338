<template>
    <q-item clickable @click="goTo(item)" >
        <q-item-section>{{ $t(item.title) }}</q-item-section>
        <q-item-section side v-if="hasChildren(item)">
            <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu v-if="hasChildren(item)">
            <q-list v-for="it in item.children">
                <AdminTopMenuItem :item="it" ></AdminTopMenuItem>
            </q-list>
        </q-menu>
    </q-item>
</template>
<script lang="ts" setup>
import type { MenuItem } from '../models/MenuItem';


const props = defineProps<{
    item: MenuItem
}>()

const router = useRouter();

function hasChildren(item: MenuItem): boolean {

  if (item.children && item.children?.length > 0) return true; else return false;
}

function goTo(item: MenuItem) {

  if (item.route) {

    router.push({path:item.route});
  }
}

</script>