<template>
     <div class="tw-flex tw-flex-col tw-cursor-pointer tw-items-center tw-gap-0 tw-w-min tw-h-min " @click="goToCart">
        <q-btn no-caps flat icon="shopping_cart" class="font-right-navbar tw-text-gray-300 !tw-py-0 !tw-px-1" size="16px" >
            <q-badge rounded  floating  :label="shopCart.count" />
        </q-btn>
        <span class="tw-text-gray-300 tw-font-normal tw-font-signika tw-text-sm">
            {{ shopCart.subtotal_formatted }}
        </span>
    </div>
</template>
<script lang="ts" setup>
import { useShopCartStore } from '~/stores/shopCartStore';


const router = useRouter();
const shopCartStore = useShopCartStore()

const {shopCart} = storeToRefs(shopCartStore)

async function goToCart() {
    router.push({path: '/cart'})

}

</script>
